<template>
  <div class="user-cennter">
    <div class="c-width cf">
      <div class="menu fl">
        <!-- <div class="menu-name">我的账号</div> -->
        
        <router-link to="/user-center/order" class="menu-item cf">
          <span class="fl">我的订单</span>
          <img
            class="fr sel"
            src="../../../assets/images/comon_iocn_jiantou_s.png"
            alt=""
          />
          <img
            class="fr nosel"
            src="../../../assets/images/comon_iocn_jiantou_n.png"
            alt=""
          />
        </router-link>

<!--        <div @click="openPage" class="menu-item cf">
          <span class="fl">我的订单</span>
          <img
                  class="fr sel"
                  src="../../../assets/images/comon_iocn_jiantou_s.png"
                  alt=""
          />
          <img
                  class="fr nosel"
                  src="../../../assets/images/comon_iocn_jiantou_n.png"
                  alt=""
          />
        </div>-->

         <router-link to="/user-center/order-online" class="menu-item cf">
          <span class="fl">在线下单</span>
          <img
            class="fr sel"
            src="../../../assets/images/comon_iocn_jiantou_s.png"
            alt=""
          />
          <img
            class="fr nosel"
            src="../../../assets/images/comon_iocn_jiantou_n.png"
            alt=""
          />
        </router-link>
        <router-link to="/user-center/user-data" class="menu-item cf">
          <span class="fl">个人信息</span>
          <img
            class="fr sel"
            src="../../../assets/images/comon_iocn_jiantou_s.png"
            alt=""
          />
          <img
            class="fr nosel"
            src="../../../assets/images/comon_iocn_jiantou_n.png"
            alt=""
          />
        </router-link>
        <router-link to="/user-center/child-account" class="menu-item cf">
          <span class="fl">子账号管理</span>
          <img
            class="fr sel"
            src="../../../assets/images/comon_iocn_jiantou_s.png"
            alt=""
          />
          <img
            class="fr nosel"
            src="../../../assets/images/comon_iocn_jiantou_n.png"
            alt=""
          />
        </router-link>
        <router-link v-if="usertype != 1" to="/user-center/over-data" class="menu-item cf">
          <span class="fl">修改资料</span>
          <img
            class="fr sel"
            src="../../../assets/images/comon_iocn_jiantou_s.png"
            alt=""
          />
          <img
            class="fr nosel"
            src="../../../assets/images/comon_iocn_jiantou_n.png"
            alt=""
          />
        </router-link>
      </div>
      <div class="fr">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      usertype:2
    }
  },
  created(){
   if(this.$store.state.isLogin == 0){
     this.$alert('欢迎来到矜群科技', '提示', {
          confirmButtonText: '确定',
          callback: action => {
                 this.$router.replace('/login')
          }
        });
   }
  },
  methods:{

    openPage() {
      console.log(this)
      this.$router.push({
        path: '/user-center/order'
      })
    },

     setData: function () {
            let userInfo = JSON.parse(localStorage['user']);
            console.log(userInfo, 123123);
                // this.license_img = userInfo.license_img
                this.usertype = userInfo.user_type
        }
  }
};
</script>
<style  scoped>
@import "../style.css";
</style>>